import React from 'react';
import Banner from './Banner';
import Footer from './Footer';
import Navbar from './Navbar';
import Products from './Products';
import Slider from './Slider';

const Home = () => {

    return (
        <div>
            <Navbar />
            <Banner />
            <Products />
            <Slider />
            <Footer />
        </div>
    );
};

export default Home;