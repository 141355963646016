import React from 'react';

const Products = () => {
    return (
        <div className="hero min-h-screen my-24">
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-14'>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src="https://cdn.pixabay.com/photo/2016/10/16/15/18/new-home-1745382_960_720.jpg" alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Shoes!</h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <button className="btn btn-outline btn-success">See Details..</button>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src="https://cdn.pixabay.com/photo/2018/07/08/18/25/lake-geneva-3524431_960_720.jpg" alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Shoes!</h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <button className="btn btn-outline btn-success">See Details..</button>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-100 shadow-xl">
                    <figure><img src="https://cdn.pixabay.com/photo/2014/03/18/23/21/brick-home-290315_960_720.jpg" alt="Shoes" /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Shoes!</h2>
                        <p>If a dog chews shoes whose shoes does he choose?</p>
                        <div className="card-actions justify-end">
                            <button className="btn btn-outline btn-success">See Details..</button>
                        </div>
                    </div>
                </div>
                <div className="card w-96 bg-base-200 shadow-xl">
                <figure><img src="https://cdn.pixabay.com/photo/2017/06/16/15/58/luxury-home-2409518_960_720.jpg" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Shoes!</h2>
                    <p>If a dog chews shoes whose shoes does he choose?</p>
                    <div className="card-actions justify-end">
                        <button className="btn btn-outline btn-success">See Details..</button>
                    </div>
                </div>
            </div>
            <div className="card w-96 bg-base-100 shadow-xl">
                <figure><img src="https://cdn.pixabay.com/photo/2017/06/16/15/58/luxury-home-2409518_960_720.jpg" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Shoes!</h2>
                    <p>If a dog chews shoes whose shoes does he choose?</p>
                    <div className="card-actions justify-end">
                        <button className="btn btn-outline btn-success">See Details..</button>
                    </div>
                </div>
            </div>
            <div className="card w-96 bg-base-100 shadow-xl">
                <figure><img src="https://cdn.pixabay.com/photo/2014/03/18/23/21/brick-home-290315_960_720.jpg" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="card-title">Shoes!</h2>
                    <p>If a dog chews shoes whose shoes does he choose?</p>
                    <div className="card-actions justify-end">
                        <button className="btn btn-outline btn-success">See Details..</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Products;